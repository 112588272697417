import React from 'react';

const Splash = () => {
  return (
    <div className="container mt-5"> 
      <h2 class="display-5 fw-bolder text-center"><span class="text-gradient d-inline">Full Sprint Dashboard Context</span></h2>
      <p className="text-left">Sprint Genie can provide more dynamic recommendations when we have multiple data points to work from, 
      giving you a better picture of what you and your team need to follow up on. Some data may seem trivial on its own, but we take the 
      time to connect the dots and let you know when we see something out of place. </p> 
      <div class="text-center"><img className="img-fluid" src="/screenshot_jirareport.png" alt="description" /></div>
      <p className="text-left py-2">In this example we see a typical JIRA sprint dashboard with 4 individual widgets representing the progress
      on a company's sprint. The pie chart and health gadget give high level summaries while the two tables of sprint stories and tasks 
      give context into those high level items. We looked at each ticket title and Epic to understand how this team operates, and 
      whether they are balancing the right amount of new work with existing maintenance and improvements. In some cases the data is clear, 
      but Sprint Genie knows when a team isn't delegating effectively, or whether there's significant scope creep, as is the case with this report.  </p> 
      <div class="text-center"><img className="img-fluid" src="/report_teaminspace.png" alt="description" /></div>
      <p className="text-left py-2">We provided 8 data points with details that can help this team optimize their next sprint and beyond. 
      Our reports are simple and easy to read, and provide more feedback than your development teams are normally willing to give.</p> 
      
    </div>
  );
};

export default Splash;
