import React from 'react';

const Examples = () => {
  return (
    <div className="container mt-5"> {/* 'container' for width control and 'text-center' to center the text, 'mt-5' for margin-top */}
      <h2 class="display-5 fw-bolder text-center"><span class="text-gradient d-inline">Example Reports</span></h2>
      <p className="text-left">We can give you a ton of insight no matter how much context we have about your sprints. Click into
      our sample reports to see how we can accelerate action items and help you make decisions faster.</p> 
      <div class="container">
      <div class="row">
        <div class="col-md-6">
        
          <a href="example-simple" class="d-block">
            <p>Single Widget Summary</p>
            <img src="/screenshot_bamboobuilds.png" class="img-fluid" alt="Descriptive Alt Text" />
            
          </a></div>
        <div class="col-md-6">
        
          <a href="example-complex" class="d-block">
          <p>Full Dashboard Report</p>
            <img src="/screenshot_jirareport.png" class="img-fluid" alt="Descriptive Alt Text" />
            
          </a>
          </div></div>
      </div>
      </div>
  );
};

export default Examples;
