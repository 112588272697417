import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Splash from './Splash';
import Examples from './Examples';
import ExampleSimple from './ExampleSimple';
import ExampleComplex from './ExampleComplex';
import ThankYouPage from './ThankYouPage';
import FormPage from './FormPage';

import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Splash />} />
        <Route exact path="/examples" element={<Examples />} />
        <Route exact path="/example-simple" element={<ExampleSimple />} />
        <Route exact path="/example-complex" element={<ExampleComplex />} />
        <Route exact path="/submit" element={<FormPage />} />
        <Route path="/thank-you" element={<ThankYouPage />} />
      </Routes>
    </Router>
  );
};

export default App;
