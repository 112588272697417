import React from 'react';

const Splash = () => {
  return (
    <div className="container mt-5"> {/* 'container' for width control and 'text-center' to center the text, 'mt-5' for margin-top */}
      <h2 class="display-5 fw-bolder text-center"><span class="text-gradient d-inline">Single Widget Context</span></h2>
      <p className="text-left">Sprint Genie can provide broad insights and recommendations even with minimal context. 
      We combine elements of the data that you provide with industry knowledge and best practices, 
      to deliver a report that's easy to understand and take action. </p> 
      <div class="text-center"><img className="img-fluid" src="/screenshot_bamboobuilds.png" alt="description" /></div>
      <p className="text-left py-2">In this example we review a simple dashboard widget about Bamboo CICD builds associated with a sprint (via the Bamboo Plan Plugin). 
      There are 2 different pipelines with minimal data about when the builds ran, who made changes, duration, and pass/fail rate for the last 10 builds. 
      Pretty simple right? But what does a Sprint Genie report look like based off of just a few talking points? </p> 
      <div class="text-center"><img className="img-fluid" src="/report_bamboobuilds.png" alt="description" /></div>
      <p className="text-left py-2">We can extract a lot of insights even from simple dashboards like this. 
      We want to bubble up things that you didn't think of and combine specific data points with best practice recommendations. 
      We should be an extension of your entire team, providing subject matter expertise to help you become efficient and balance 
      speed with quality. </p> 
      
    </div>
  );
};

export default Splash;
