import React from 'react';
import { Link } from 'react-router-dom';
import logoJira from './logo_jira.png'; // Update with the correct path to your image

const Splash = () => {
  return (
    <div className="row gx-5 align-items-center">
      <div className="col-xxl-5">
        <div className="text-center text-xxl-start">
          <h1 className="display-3 fw-bolder mb-5">
            <span className="text-gradient d-inline">Let us get your sprints back on track</span>
          </h1>
          <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xxl-start mb-3">
            <Link to="/submit" className="btn btn-primary btn-lg px-5 py-3 me-sm-3 fs-6 fw-bolder">Request a Report</Link>
            {/* The projects button can be uncommented and used if needed */}
            {/* <Link to="/projects" className="btn btn-outline-dark btn-lg px-5 py-3 fs-6 fw-bolder">View Projects</Link> */}
          </div>
        </div>
      </div>
      <div className="col-xxl-7">
        <div className="d-flex justify-content-center mt-5 mt-xxl-0">
          <div className="profile">
            <img className="profile-img" src={logoJira} alt="Profile illustration" />            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Splash;
