import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

const FormPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [file, setFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.size <= 32 * 1024 * 1024) { // 32MB size limit
      setFile(selectedFile);
    } else {
      alert('File is too large.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    console.log(isSubmitting);
    if (!file) {
      alert('Please upload a file.');
      setIsSubmitting(false);
      return;
    }

    if (!captcha) {
      alert('Please complete the reCAPTCHA.');
      setIsSubmitting(false);
      return;
    }

    // FormData to handle file upload
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', name);
    formData.append('email', email);
    formData.append('message', message);
    formData.append('captcha', captcha);

    try {
      // 1. Verify reCAPTCHA and upload the file in one go on the server-side
      const boxResponse = await axios.post('https://api.sprintgenie.co/box-upload-endpoint', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(boxResponse);
      // Check if the server-side reCAPTCHA verification and file upload was successful
      if (boxResponse.data.success) {
        // 2. Send the email with SendGrid
        const sendGridResponse = await axios.post('https://api.sprintgenie.co/sendgrid-endpoint', {
          name,
          email,
          fileUrl: boxResponse.data.data, // Use the file URL returned from the server
        });

        if (sendGridResponse.data.success) {
          // 3. Redirect to the Thank You page
          navigate('/thank-you');
        } else {
          alert('Failed to send email.');
        }
      } else {
        alert(boxResponse.data.message || 'File upload failed.');
      }
    } catch (error) {
      console.error('Submission error:', error);
      alert('Submission failed: ' + error.message);
    }

    setIsSubmitting(false);
  };

  const onRecaptchaChange = (value) => {
    setCaptcha(value);
  }

  


  return (
    <div>
    <div className="text-center mb-5">
    <h1 className="display-5 fw-bolder mb-0"><span className="text-gradient d-inline">Request a Report</span></h1>
    </div>
    <div className="row gx-5 justify-content-center">
    <p className="lead fw-normal text-muted mb-0">Submit a PDF of your JIRA sprint dashboards, with as much
    context as possible. The more we know the better our findings. <strong>Your first report is FREE, and only $25 per JIRA sprint dashboard after that.</strong>
    </p>
    <p>&nbsp;</p>
    
      <div className="col-lg-8 col-xl-6">
        <form onSubmit={handleSubmit}>
          <div className="form-floating mb-3">
            <input className="form-control" id="name" onChange={(e) => setName(e.target.value)} type="text" placeholder="Enter your name..." required />
            <label htmlFor="name">Full name</label>
            <div className="invalid-feedback">A name is required.</div>
          </div>

          <div className="form-floating mb-3">
            <input className="form-control" id="email" onChange={(e) => setEmail(e.target.value)} type="email" placeholder="name@example.com" required />
            <label htmlFor="email">Email address</label>
            <div className="invalid-feedback">An email is required.</div>
            <div className="invalid-feedback">Email is not valid.</div>
          </div>

          <div className="form-floating mb-3">
          <input
            name="file"
            type="file"
             className="form-control"
            id="file"
            onChange={handleFileChange}
            accept="application/pdf"
            required
          />
            
            <label htmlFor="file">Sprint Data Upload</label>
            <div className="invalid-feedback">A file is required.</div>
          </div>

          <div className="form-floating mb-3">
            <textarea className="form-control" id="message" onChange={(e) => setMessage(e.target.value)} placeholder="Enter additional context or instructions here..." style={{ height: '10rem' }}></textarea>
            <label htmlFor="message">Additional Context or Instructions</label>
            <div className="invalid-feedback">A message is required.</div>
          </div>
          <div className="form-floating mb-3">
            <ReCAPTCHA
          sitekey="6LeJ0gcpAAAAAJbtd4-nTUhMwFmXUabDgk308iFd"
          onChange={onRecaptchaChange}
          
        />
          </div>
          
          <div className="d-none" id="submitSuccessMessage">
            <div className="text-center mb-3">
              <div className="fw-bolder">Form submission successful!</div>
              To activate this form, sign up at
              <br />
              <a href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a>
            </div>
          </div>

          <div className="d-none" id="submitErrorMessage">
            <div className="text-center text-danger mb-3">Error sending message!</div>
          </div>

          <div className="d-grid">
            <button className="btn btn-primary btn-lg" id="submitButton" type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>    
    
    </div>


  );
};

export default FormPage;
