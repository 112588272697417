import React from 'react';

const ThankYouPage = () => {
  return (
    <div className="container text-center mt-5"> {/* 'container' for width control and 'text-center' to center the text, 'mt-5' for margin-top */}
      <h1 className="display-3">Thank You!</h1> {/* 'display-3' for large, stand-out heading */}
      <p className="lead">Your submission has been received.</p> {/* 'lead' to make the paragraph text slightly larger and more lead-like */}
      {/* Optionally, you can add more Bootstrap elements like a button to go back to the home page */}
      <a href="/" className="btn btn-primary mt-3">Go to Sprint Genie</a> {/* 'btn btn-primary' for Bootstrap styled button */}
    </div>
  );
};

export default ThankYouPage;

